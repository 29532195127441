import React from "react";
import loadable from "@loadable/component";
//antd에서 아이콘 가져오기
import {ShopOutlined, UserOutlined} from "@ant-design/icons";
//회원 전용 루트
import { Navigate } from "react-router";

//로그인 없이 이용가능 페이지
export const publicRoutes = [
  //첫시작 로그인으로 설정 => ('/' 해당 url 사용 안함)
  {
    exact: true,
    path: "/",
    component: () => {
      if (localStorage.getItem("token")) {
        return <Navigate to="/user" />
      } else {
        return <Navigate to="/login" />
      }
    },
  },
  {
    exact: true,
    path: "/login",
    component: loadable(() => import("../pages/auth/Login")),
  },
];

//로그인 시에만 이용가능 페이지
export const privateRoutes = [
  {
    exact: true,
    path: "/user",
    sidebar: {
      icon: <UserOutlined />,
      label: "회원 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "회원 리스트",
        },
        component: loadable(() => import("../pages/user/List")),
      },
    ],
  },
  {
    exact: true,
    path: "/product",
    sidebar: {
      icon: <ShopOutlined />,
      label: "상품 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "상품조회",
        },
        component: loadable(() => import("../pages/product/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/product/Detail")),
      },
      {
        exact: true,
        path: "/sale",
        sidebar: {
          label: "판매가관리",
        },
        component: loadable(() => import("../pages/product/Sale")),
      },
      {
        exact: true,
        path: "/category",
        sidebar: {
          label: "카테고리관리",
        },
        component: loadable(() => import("../pages/product/Category")),
      },
    ],
  },
];
